import React from "react";
import theme from "theme";
import { Theme, Text, Button, Section, Image, Box, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Über uns | The Pitch Tavern!
			</title>
			<meta name={"description"} content={"Vereint den Nervenkitzel des Spiels mit dem Herzen der Gastfreundschaft!"} />
			<meta property={"og:title"} content={"Über uns | The Pitch Tavern!"} />
			<meta property={"og:description"} content={"Vereint den Nervenkitzel des Spiels mit dem Herzen der Gastfreundschaft!"} />
			<meta property={"og:image"} content={"https://cyroqau.com/img/counter_1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cyroqau.com/img/1651826.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cyroqau.com/img/1651826.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cyroqau.com/img/1651826.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cyroqau.com/img/1651826.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cyroqau.com/img/1651826.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cyroqau.com/img/1651826.png"} />
			<meta name={"msapplication-TileColor"} content={"https://cyroqau.com/img/1651826.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Über The Pitch Tavern
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Tauchen Sie ein in die Essenz von The Pitch Tavern, einer einzigartigen Mischung aus elektrisierender sportlicher Energie und gastronomischen Wundern. Entdecken Sie, was uns zum Hotspot für Sportbegeisterte und Feinschmecker gleichermaßen macht.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				background="--color-red"
				border-radius="8px"
				href="/contacts"
			>
				Kontakt
			</Button>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://cyroqau.com/img/inside_9.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Unsere Essenz:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Das Herzstück von The Pitch Tavern ist eine einfache, aber tiefe Überzeugung: Sport ist nicht nur ein Spiel, sondern ein Lebensstil, und jeder Lebensstil verdient eine geschmackvolle Ergänzung. Hier verbinden wir nahtlos die Lebendigkeit eines Stadions mit der Intimität eines Gourmet-Bistros und sorgen dafür, dass jeder Besuch zu einer unvergesslichen Erinnerung wird.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Genese:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Auch wenn unsere Ursprünge so unterschiedlich sind wie die Fans, die unser Lokal besuchen, so gibt es doch einen gemeinsamen Nenner, der uns alle verbindet - die Liebe zum Spiel und die Wertschätzung für exquisite Speisen und Getränke. Mit diesen Grundpfeilern wurde The Pitch Tavern ins Leben gerufen, mit dem Ziel, ein Schmelztiegel für Fans aus allen Gesellschaftsschichten zu werden.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://cyroqau.com/img/menu_2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://cyroqau.com/img/menu_5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Kulinarisches Abenteuer:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Das Essen in der Pitch Tavern ist nicht einfach nur ein nachträglicher Gedanke. Wir sind stolz auf unsere sorgfältig zusammengestellte Speisekarte, in der jedes Gericht eine eigene Geschichte erzählt. Inspiriert von den unzähligen Aromen, die man in Stadien auf der ganzen Welt findet, ist unsere Küche ein kulinarischer Spielplatz, auf dem Tradition auf Innovation trifft.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Ambiente & Einrichtung:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Modern und industriell, aber mit einem Hauch klassischer Eleganz, sind die Innenräume der Pitch Tavern ein optischer Genuss. Das dynamische Nebeneinander von elegantem Design, stimmungsvoller Beleuchtung und den neuesten Sportgeräten sorgt für eine lebendige und doch gemütliche Atmosphäre. Unser Sky-view Deck und unser Loft Retreat bieten für jede Stimmung den passenden Raum.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://cyroqau.com/img/inside_8.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://cyroqau.com/img/menu_3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
							Verpflichtung zur Qualität:
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
							Wir sind der Meinung, dass unser Service, unser Essen und unser Gesamterlebnis von höchster Qualität sein müssen. Unser Team wird ständig geschult, damit es immer auf dem neuesten Stand des Sports und der Gastronomie ist. Wenn Sie die Pitch Tavern betreten, können Sie sicher sein, dass Sie sich in guten Händen befinden.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0.25)"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline3"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Wir laden Sie ein, The Pitch Tavern aus erster Hand zu erleben. Feiern Sie mit uns das Spiel, die Geschmäcker und die unzähligen Geschichten, die in unseren Mauern warten. Willkommen an einem Ort, an dem jeder Jubel, jeder Bissen und jeder Augenblick zählt.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});